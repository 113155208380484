import state from './moduleCampaignState';
import mutations from './moduleCampaignMutations';
import actions from './moduleCampaignActions';
import getters from './moduleCampaignGetters';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
