const INITIAL_STATUS_REVIEW_CONFIG = {
  available: false,
  partner: null,
  displayed: null,
  program: false,
  qbank: false,
  description: null,
};

export default {
  campaigns: [],
  products: [],
  offlineActivities: [],
  statusReview: {
    organic: INITIAL_STATUS_REVIEW_CONFIG,
    syndicated: INITIAL_STATUS_REVIEW_CONFIG,
    techProgram: INITIAL_STATUS_REVIEW_CONFIG,
    badgets: INITIAL_STATUS_REVIEW_CONFIG,
    awards: INITIAL_STATUS_REVIEW_CONFIG,
    quotes: INITIAL_STATUS_REVIEW_CONFIG,
    mediaLogo: INITIAL_STATUS_REVIEW_CONFIG,
    other: INITIAL_STATUS_REVIEW_CONFIG,
  },
  summary: {},
};
