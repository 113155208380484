import { RetailerMutation } from '@/store/mutationTypes';

export default {
  [RetailerMutation.ADD](state, item) {
    state.retailers.unshift(item);
  },
  [RetailerMutation.SET](state, retailers) {
    state.retailers = retailers;
  },
  [RetailerMutation.UPDATE](state, retailer) {
    const index = state.retailers.findIndex((el) => el.id === retailer.id);
    Object.assign(state.retailers[index], retailer);
  },
  [RetailerMutation.REMOVE](state, id) {
    const retailers = state.retailers.filter((el) => el.id !== id);
    state.retailers = retailers;
  },
};
