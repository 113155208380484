import axios from '@/axios';
import { CampaignAction } from '@/store/actionTypes';
import { CampaignMutation } from '@/store/mutationTypes';

export default {
  [CampaignAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns`, item)
        .then((response) => {
          commit(CampaignMutation.ADD, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.fetchOne](context, id) {
    return new Promise((resolve, reject) => {
      return axios.get(`/campaigns/${id}`).then(resolve).catch(reject);
    });
  },
  [CampaignAction.fetch]({ commit }, query = '') {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns${query}`)
        .then((response) => {
          commit(CampaignMutation.SET, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.update]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/${item.id}`, item)
        .then((response) => {
          commit(CampaignMutation.UPDATE, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/${id}`)
        .then((response) => {
          commit(CampaignMutation.REMOVE, id);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.fetchProducts]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/products`)
        .then((response) => {
          commit(CampaignMutation.SET_PRODUCTS, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.addProduct]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns/${item.campaignId}/products`, item)
        .then((response) => {
          commit(CampaignMutation.ADD_PRODUCT, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.removeProduct]({ commit }, productId) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/campaigns/products/${productId}`)
        .then((response) => {
          commit(CampaignMutation.REMOVE_PRODUCT, productId);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.fetchProductConfig](context, productId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/products/${productId}/config`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateProductConfig](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns/products/${item.campaignProductId}/config`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.addStatusSearch](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `/campaigns/products/${item.campaignProductId}/status-search`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateStatusSearch](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/status-search/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeStatusSearch](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(
          `/campaigns/products/${item.campaignProductId}/status-search/${item.id}`
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.fetchStatusReviews]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/status-review`)
        .then((response) => {
          commit(CampaignMutation.SET_STATUS_REVIEW, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.updateStatusReview]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/${item.id}/status-review`, item)
        .then(({ data }) => {
          commit(CampaignMutation.SET_STATUS_REVIEW, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [CampaignAction.fetchOfflineActivities]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/offline-activities`)
        .then(({ data }) => {
          commit(CampaignMutation.SET_OFFLINE_ACTIVITIES, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [CampaignAction.addOfflineActivity]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/campaigns/offline-activities/${item.id}`, item)
        .then((response) => {
          commit(CampaignMutation.ADD_OFFLINE_ACTIVITY, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.updateOfflineActivity]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/offline-activities/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_OFFLINE_ACTIVITY', response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.removeOfflineActivity]({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/campaigns/offline-activities/${id}`)
        .then((response) => {
          commit(CampaignMutation.REMOVE_OFFLINE_ACTIVITY, id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CampaignAction.addDisplayAndPaidSearchPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `/campaigns/products/${item.campaignProductId}/display-paid-search-planning`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateDisplayAndPaidSearchPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/display-paid-search-planning/${item.id}`,
          item
        )
        .then(resolve)
        .catch(reject);
    });
  },
  [CampaignAction.removeDisplayAndPaidSearchPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(
          `/campaigns/products/${item.campaignProductId}/display-paid-search-planning/${item.id}`
        )
        .then(resolve)
        .catch(reject);
    });
  },
  [CampaignAction.fetchDisplayAndPaidSearchResult](context, campaignProductId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(
          `/campaigns/products/${campaignProductId}/display-paid-search-result`
        )
        .then(resolve)
        .catch(reject);
    });
  },
  [CampaignAction.updateDisplayAndPaidSearchResult](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/display-paid-search-result/${item.id}`,
          item
        )
        .then(resolve)
        .catch(reject);
    });
  },
  [CampaignAction.addCrmPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `/campaigns/products/${item.campaignProductId}/crm-planning`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateCrmPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/crm-planning/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeCrmPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(
          `/campaigns/products/${item.campaignProductId}/crm-planning/${item.id}`
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.fetchCrmResult](context, campaignProductId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/products/${campaignProductId}/crm-result`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateCrmResult](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/crm-result/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.addSocialMediaPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `/campaigns/products/${item.campaignProductId}/social-media-planning`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateSocialMediaPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/social-media-planning/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeSocialMediaPlanning](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(
          `/campaigns/products/${item.campaignProductId}/social-media-planning/${item.id}`
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.fetchSocialMediaResult](context, campaignProductId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/products/${campaignProductId}/social-media-result`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateSocialMediaResult](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/social-media-result/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.addReviewPlanningItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `/campaigns/products/${item.campaignProductId}/review-planning-item`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateReviewPlanningItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/review-planning-item/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeReviewPlanningItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(
          `/campaigns/products/${item.campaignProductId}/review-planning-item/${item.id}`
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.addReviewResultItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `/campaigns/products/${item.campaignProductId}/review-result-item`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.updateReviewResultItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/campaigns/products/${item.campaignProductId}/review-result-item/${item.id}`,
          item
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.removeReviewResultItem](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(
          `/campaigns/products/${item.campaignProductId}/review-result-item/${item.id}`
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [CampaignAction.fetchSummary]({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/campaigns/${campaignId}/summary`)
        .then((response) => {
          commit(CampaignMutation.SET_SUMMARY, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [CampaignAction.updateSummaryOfflineActivity]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/campaigns/${item.campaignId}/summary/offline-activities`, item)
        .then((response) => {
          commit(CampaignMutation.SET_SUMMARY, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
};
