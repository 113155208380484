import { UserRole } from '@/enums/UserRole';

export default {
  computed: {
    ableToEditCampaign() {
      const { id, role } = this.$store.state.AppActiveUser;
      const roleAbleToEdit =
        role === UserRole.SYSADMIN.value || role === UserRole.GLOBAL.value;

      return (userId) => id === userId || roleAbleToEdit;
    },
    ableToDeleteCampaign() {
      const { id, role } = this.$store.state.AppActiveUser;
      const roleAbleToEdit =
        role === UserRole.SYSADMIN.value || role === UserRole.GLOBAL.value;

      return (userId) => id === userId || roleAbleToEdit;
    },
  },
};
