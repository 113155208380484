export const CampaignStatus = Object.freeze({
  NEW: { label: 'New', value: 'new', color: 'primary' },
  IN_PROGRESS: { label: 'In progress', value: 'in_progress', color: 'warning' },
  DONE: { label: 'Done', value: 'done', color: 'success' },
});

export const campaignStatusList = [...Object.values(CampaignStatus)];

export const getStatusByValue = (val) =>
  campaignStatusList.find((el) => el.value === val);

export const getStatusName = (val = null) => {
  if (val === null) return '';

  return getStatusByValue(val).label;
};

export const getStatusColor = (val = null) => {
  if (val === null) return '';

  return getStatusByValue(val).color;
};
