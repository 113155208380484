export default {
  data() {
    return {
      selected: [],
      itemsPerPage: 10,
      isMounted: false,

      addNewDataSidebar: false,
      sidebarData: {},
    };
  },
  computed: {
    currentPage() {
      if (!this.isMounted) return 0;

      return this.$refs.table.currentx;
    },
  },
  methods: {
    changeItemsPerPage(val) {
      this.itemsPerPage = val;
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
