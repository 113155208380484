<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} CAMPAIGN</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- NAME -->
      <div>
        <vs-label text="Name" />
        <vs-input
          v-model="dataName"
          class="w-full"
          name="name"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">
          {{ errors.first('name') }}
        </span>
      </div>

      <!-- RETAILER -->
      <div class="mt-4">
        <vs-label text="Retailer" />
        <v-select
          :options="retailerList"
          :clearable="false"
          v-model="dataRetailer"
          name="retailer"
        />
      </div>

      <!-- DESCRIPTION -->
      <div class="mt-4">
        <vs-label text="Description" />
        <vs-textarea v-model="dataDescription" name="description" />
      </div>

      <!-- STATUS -->
      <div class="mt-4">
        <vs-label text="Status" />
        <v-select
          :options="campaignStatusList"
          :clearable="false"
          v-model="dataStatus"
          name="campaign-status"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('campaign-status')"
          >{{ errors.first('campaign-status') }}</span
        >
      </div>

      <!-- START DATE -->
      <div class="mt-4">
        <vs-label text="Start date" />
        <datepicker
          placeholder="Select Date"
          class="w-full"
          v-model="dataStartDate"
        />
      </div>

      <!-- END DATE -->
      <div class="mt-4">
        <vs-label text="End date" />
        <datepicker
          placeholder="Select Date"
          class="w-full"
          v-model="dataEndDate"
        />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import { mapActions, mapState } from 'vuex';

import {
  CampaignStatus,
  campaignStatusList,
  getStatusByValue,
} from '@/enums/CampaignStatus';

import { CampaignAction } from '@/store/actionTypes';

import sidebarMixin from '@/mixins/sidebarMixin';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    vSelect,
    Datepicker,
  },
  data() {
    return {
      campaignStatusList,
      dataId: null,
      dataName: '',
      dataRetailer: null,
      dataDescription: '',
      dataStatus: CampaignStatus.NEW,
      dataStartDate: new Date(),
      dataEndDate: null,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          name,
          description,
          status,
          start_date,
          end_date,
          retailer_id,
        } = JSON.parse(JSON.stringify(this.data));

        this.dataId = id;
        this.dataName = name;
        this.dataRetailer = this.retailerList.find(
          ({ value }) => value === retailer_id
        );
        this.dataDescription = description;
        this.dataStatus = getStatusByValue(status);
        this.dataStartDate = start_date;
        this.dataEndDate = end_date;
        this.initValues();
      }
    },
  },
  computed: {
    ...mapState('retailer', {
      retailers: ({ retailers }) => retailers,
    }),

    retailerList() {
      return this.retailers.map(({ id, name }) => ({ value: id, label: name }));
    },
    isFormValid() {
      return !this.errors.any() && this.dataName;
    },
  },
  methods: {
    ...mapActions('campaign', [CampaignAction.add, CampaignAction.update]),

    initValues() {
      if (this.data.id) return;

      this.dataId = null;
      this.dataName = '';
      this.dataRetailer = null;
      this.dataDescription = '';
      this.dataStatus = CampaignStatus.NEW;
      this.dataStartDate = new Date();
      this.dataEndDate = null;
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const obj = {
        id: this.dataId,
        name: this.dataName,
        description: this.dataDescription,
        status: this.dataStatus.value,
        start_date: this.dataStartDate,
        end_date: this.dataEndDate,
        retailer_id: this.dataRetailer ? this.dataRetailer.value : null,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateCampaign(obj);

          this.notifySuccess(
            'Campaign updated',
            'The campaign has been successfully updated'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating a campaign.');
        }
      } else {
        delete obj.id;

        try {
          await this.addCampaign(obj);

          this.notifySuccess(
            'Campaign added',
            'The campaign has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding a campaign.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close-sidebar');
      this.initValues();
    },
  },
};
</script>
