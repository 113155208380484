import state from './moduleRetailerState';
import mutations from './moduleRetailerMutations';
import actions from './moduleRetailerActions';
import getters from './moduleRetailerGetters';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
